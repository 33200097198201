.is-muted
  color: #d6d6d6

.is-uppercase
  text-transform: uppercase

.is-bold
  font-weight: 700 !important

.is-black
  color: #313131 !important

.is-success
  color: #52c41a

.is-danger
  color: #f5222d

.ant-input-search .ant-input-group-addon
  left: -1px
  padding: 0
  border: 0

.ant-form-actions-wrapper
  margin-top: 24px
  display: flex
  justify-content: right

.ant-page-header-heading-title
  line-height: 40px

.w-full
  width: 100%

.flex
  display: flex

.align-items-end
  align-items: flex-end

.align-items-center
  align-items: center

.ml-auto
  margin-left: auto

.is-italic
  font-style: italic

.is-underline
  text-decoration: underline

.ant-page-header-heading-sub-title
  align-self: flex-end

.search-filter-button
  display: flex
  justify-content: end
  margin-top: 16px

.vertical-divider
  display: inline-block
  margin: 0 10px
  width: 2px
  height: 16px
  background: #333

.ant-popover-no-padding
  .ant-popover-inner-content
    padding: 0

.outlined-btn
  background-color: #F2F5F5
  color: #F79622
  font-weight: bold
  border: solid 1px
  padding: 0px 20px 0px 20px
  &:hover
    background-color: #F79622
    color: #fff
    border: solid 1px
    border-color: #F79622

.filled-btn 
  color: #fff
  background-color: #F79622
  border-color: #F79622
  font-weight: bold
  padding: 0px 20px 0px 20px
  &:hover
    background-color: darken(#F79622, 12)
    color: #fff
    border: solid 1px
    border-color: #F79622

.rounded-btn
  border-radius: 60px

.ant-layout-header
  .ant-menu-item.ant-menu-item-selected
    border-bottom: 8px solid #ff7a7a !important

.ant-page-header
  margin: 0px -24px 24px -24px !important

.ellipsis-container
  height: 30px
  width: 30px
  border-radius: 30px
  display: inline-flex
  align-items: center
  justify-content: center
  cursor: pointer
  margin-right: 5px
  background-color: #fff !important
  &.background-initial
    background-color: initial !important

// Descriptions
.ant-descriptions-item-content
  font-weight: 700 !important

@import "modules/modal"
@import "modules/alert"
@import "modules/labels"