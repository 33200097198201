$success-color: #34B031
$success-background: #EBF7EB
$warning-color: #F79622
$warning-background: #fff1e0

.ant-alert
  &.ant-alert-success
    border-color: $success-color
    background-color: $success-background
    .ant-alert-message
      color: $success-color
  &.ant-alert-warning
    border-color: $warning-color
    background-color: $warning-background
    .ant-alert-message
      color: $warning-color