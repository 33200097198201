.version-label
  display: inline-block
  padding: 2px 20px
  margin-top: 5px
  border-radius: 999px
  color: white
  border: 1px solid
  &.create
    background: rgba(239, 191, 65, 0.2)
    border-color: rgba(239, 191, 65, 1)
    color: rgba(239, 191, 65, 1)
  &.update
    background: rgba(112, 186, 212, 0.3)
    border-color: rgba(112, 186, 212, 1)
    color: rgba(112, 186, 212, 1)
  &.destroy
    background: rgba(255, 122, 122, 0.15)
    border-color: rgba(255, 122, 122, 1)
    color: rgba(255, 122, 122, 1)